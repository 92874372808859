var smallScreen = 1200;
$(document).ready(function() {
  $('#dispoForm_secteur').selectpicker();
  $('#colorselector').colorselector();
});

$(window).on('popstate', function() {
  $('.modal').modal('hide');
  $( ".modal-backdrop" ).remove();
  $( ".in" ).remove();
  $( ".dhx_cal_cover" ).remove();
});

$(document).on('click', ".sidebar-dropdown > a", function () {
  $(".sidebar-submenu").slideUp(200);
  if (
    $(this)
      .parent()
      .hasClass("active")
  ) {
    $(".sidebar-dropdown").removeClass("active");
    $(this)
      .parent()
      .removeClass("active");
  } else {
    $(".sidebar-dropdown").removeClass("active");
    $(this)
      .next(".sidebar-submenu")
      .slideDown(200);
    $(this)
      .parent()
      .addClass("active");
  }
});


$(document).on('click', "#close-sidebar", function () {
  if ($(window).innerWidth() > smallScreen) {
    var elem = $('.page-wrapper');
    elem.toggleClass("toggled");
    $(this).find('span').toggleClass('toggle');
    if (elem.hasClass("toggled")) {
      $('.container-fluid').css({paddingLeft: "20px"});
    } else {
      $('.container-fluid').css({paddingLeft: "70px"});
    }
  }
});

$(document).on("mouseenter", ".calendar_holiday_header", function () {
  var title = $(this).attr('title');
  $(this).html('<b>' + title + '</b>');
});

$(document).on("mouseleave", ".calendar_holiday_header", function () {
  var text = $(this).data('text');
  $(this).html('<b>' + text + '</b>');
});

$(window).on('load resize', function () {
  screenClass();
});

function screenClass() {
  var elem = $('.page-wrapper');
  if ($(window).innerWidth() > smallScreen) {
    elem.addClass("toggled");
    $('#close-sidebar').find('span').removeClass('toggle');
    $('.container-fluid').css({paddingLeft: "20px"});
  } else {
    elem.removeClass("toggled");
    $('#close-sidebar').find('span').addClass('toggle');
    $('.container-fluid').css({paddingLeft: "70px"});
  }
  $("#panel").slideDown("slow");
  /*$('.btn-val-plus').on('click',function(){
    var val = $(this).siblings('.btn-val-value');
    val.text(parseInt(val.text())+1);
  });

  $('.btn-val-minus').on('click',function(){
    var val = $(this).siblings('.btn-val-value');
    if(parseInt(val.text()) > 0) val.text(parseInt(val.text())-1);
  });*/

  $("#validadress").click(function () {
    $("label.valadress").text($("#adress").val());
    $("label.postal").text($("#codepostal").val());
  });

  $(".suivant").click(function () {
    var val = parseInt($('.step').val());
    switch (val) {
      case 1 :
        $('#step1').hide();
        $('#step2').show();
        break;
      case 2 :
        $('#step2').hide();
        $('#step3').show();
        break;
      case 3 :
        $('#step3').hide();
        $('#step4').show();
        break;
    }
    val++;
    $('.step').val(val);
  });

  $(".precedent").click(function () {
    var val = parseInt($('.step').val());
    val--;
    switch (val) {
      case 3 :
        $('#step4').hide();
        $('#step3').show();
        break;
      case 2 :
        $('#step3').hide();
        $('#step2').show();
        break;
      case 1 :
        $('#step2').hide();
        $('#step1').show();
        break;
    }

    $('.step').val(val);
  });
}
